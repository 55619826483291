import React, { createContext, useContext, useEffect, useState } from "react";
import {
  PushNotifications,
  Token,
  PushNotificationSchema
} from "@capacitor/push-notifications";
import { LocalNotifications } from '@capacitor/local-notifications';
import { Device } from '@capacitor/device';
import { useNavigate } from "react-router";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { getDeviceId, getDeviceInfo } from "utils/utils";
import { useDeviceRegistrationMutation, useManageEmployeeDeviceMutation } from "store/reducers/device";
import useAuth from "hooks/useAuth";
import { Device as DeviceInfo } from "types/device";
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
// Define the context structure
interface PushNotificationContextProps {
  notifications: PushNotificationSchema[];
  pushToken: string | null;
}

const PushNotificationContext = createContext<PushNotificationContextProps | undefined>(undefined);

export const PushNotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<PushNotificationSchema[]>([]);
  const [pushToken, setPushToken] = useState<string | null>(null);
  const [registerDevice] = useDeviceRegistrationMutation();
  const [manageEmployeeDevice] = useManageEmployeeDeviceMutation();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const getDevicePayloadForRegistration = async () => {
    const deviceInfo = await getDeviceInfo();
    const deviceId = await getDeviceId();
    let deviceToken = null;
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'granted') {
      deviceToken = await SecureStoragePlugin.get({ key: "push_token" });
    }
    return {
        deviceId,
        deviceToken: deviceToken?.value ?? null,
        deviceInfo
    }
  }

  const linkDeviceToEmployee = async () => {
    if(Capacitor.getPlatform() !== "web"){
    try {
        let permStatus = await PushNotifications.checkPermissions();
        const deviceInfo = await getDevicePayloadForRegistration();
        const payload:DeviceInfo = { ...deviceInfo, isNotificationActive: permStatus.receive === 'granted', isUserLoggedOut: !isLoggedIn };
        console.log("Payload Data", payload);
        const response = await manageEmployeeDevice(payload).unwrap();
    } catch (error) {
        console.error("Error linking device to employee", error);
    }
    }
  }

  const registerDeviceWithServer = async () => {
    try {
        const payload = await getDevicePayloadForRegistration();
        console.log("Payload Data", payload);
        const response = await registerDevice(payload).unwrap();
    } catch (error){
        console.error("Error registering device with server", error);
    }
  }

  useEffect(() => {
    let isMounted = true; // To prevent setting state on an unmounted component

    const addPushListeners = async () => {
      // ✅ Keep listeners outside permission check
      await PushNotifications.addListener("registration", async (token: Token) => {
        console.log("Push registration success:", token.value);
        await SecureStoragePlugin.set({ key: "push_token", value: token.value });
        await registerDeviceWithServer();
        try {
          if (isMounted) setPushToken(token.value);
        } catch (error) {
          console.error("Secure storage error:", error);
        }
      });

      await PushNotifications.addListener("pushNotificationReceived", async (notification: PushNotificationSchema) => {
        console.log("🔔 Push received:", notification);

        if (Capacitor.getPlatform() === "android") {
          await showLocalNotification(notification); // Manually trigger local notification
        } else {
          // alert(`${notification.title}\n${notification.body}`); // Show alert for iOS
        }
        setNotifications((prev) => [notification, ...prev]);
      });

      await PushNotifications.addListener("pushNotificationActionPerformed", (notification: any) => {
        console.log("✅ Notification action performed", notification);
        if (notification?.notification?.data?.click_action) {
          navigate(JSON.parse(notification.notification.data.click_action));
        }
        console.log("push_notification_clicked", notification);
      });

      await PushNotifications.addListener("registrationError", async(error) => {
        console.error("❌ Push registration error:", error);
        trackFirebaseAnalyticEvent("push_notification_registration_failed", { error });
        await registerDeviceWithServer();
      });

      await LocalNotifications.addListener("localNotificationActionPerformed", (notification) => {
        console.log("🔔 Local Notification Clicked:", notification);
        console.log("local_push_notification_clicked", notification);
      });
    };

    const registerPushNotifications = async () => {
      try {
        let permStatus = await PushNotifications.checkPermissions();
        const permissionLocalNotification = await LocalNotifications.requestPermissions();
    
        if (permStatus.receive === "prompt") {
          permStatus = await PushNotifications.requestPermissions();
        }
    
        // ✅ If Android 13+, request POST_NOTIFICATIONS permission
        if (Capacitor.getPlatform() === "android") {
          const info = await Device.getInfo();
          if (info.platform === "android" && parseInt(info.osVersion) >= 13) {
            const status = await PushNotifications.requestPermissions();
            if (status.receive !== "granted") {
              console.warn("❌ Notifications permission denied");
              return;
            }
          }
        }
    
        if (permStatus.receive === "granted") {
          console.log("✅ Push Notification Permissions Granted");
          await PushNotifications.register();
        } else {
          console.warn("⚠️ Push Notification Permission Not Granted");
        }
    
        if (permissionLocalNotification.display === "granted") {
          console.log("✅ Local Notification Permission Granted");
        }
      } catch (error) {
        console.error("❌ Error registering push notifications:", error);
      }
    };
    

    registerPushNotifications();
    addPushListeners();
    registerDeviceWithServer();

    return () => {
      isMounted = false;
    //   PushNotifications.removeAllListeners();
    //   LocalNotifications.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
        const linkEmployeeWithDevice = async () => {
            try {
                await linkDeviceToEmployee();
            } catch (error) {
                
            }
        }
        linkEmployeeWithDevice();
    }
  },[isLoggedIn]);


  return (
    <PushNotificationContext.Provider value={{ notifications, pushToken }}>
      {children}
    </PushNotificationContext.Provider>
  );
};

// Custom Hook to use Push Notifications
export const usePushNotifications = () => {
  const context = useContext(PushNotificationContext);
  if (!context) {
    throw new Error("usePushNotifications must be used within a PushNotificationProvider");
  }
  return context;
};

// Track Firebase Analytics Event
export const trackFirebaseAnalyticEvent = async (eventName: string, notification: any) => {
  try {
    const info = await Device.getInfo();
    
    const storedToken = await getDeviceId();
    if (FirebaseAnalytics && Capacitor.getPlatform() !== "web") {
      console.log("Firebase Log Event ", FirebaseAnalytics);
      FirebaseAnalytics.logEvent({
          name: eventName, params: {
          platform: info.platform, title: notification?.title || "N/A",
          body: notification?.body || "N/A", UUID: storedToken
        }
      });
    }
  } catch (error) {
    console.error("Firebase Analytics Error:", error);
  }
};

export const showLocalNotification = async (notification: any) => {
  try {
    const permissionStatus = await LocalNotifications.requestPermissions();
    if (permissionStatus.display === "granted") {
      await LocalNotifications.schedule({
        notifications: [
          {
            id: new Date().getTime(),
            title: notification.title ?? "New Notification",
            body: notification.body ?? "You have received a new notification.",
            sound: "default",
            extra: notification.data,
          },
        ],
      });
    }
  } catch (error) {
    console.error("❌ Error scheduling local notification:", error);
  }
};
