import { useRoutes } from "react-router-dom";

import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import AuthRoutes from "./AuthRoutes";
import CalculatorRoutes from "./CalculatorRoutes";

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, LoginRoutes, AuthRoutes, CalculatorRoutes]);
}
