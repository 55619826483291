import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App } from "@capacitor/app";

export const useBackButtonService = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = () => {
      console.log("Back button pressed in Capacitor App");
      navigate(-1);
    };

    // Listen for the Capacitor back button event (native back button)
    const backListener = App.addListener("backButton", ({ canGoBack }) => {
      if (canGoBack) {
        handleBackButton();
      } else {
        App.exitApp(); // Exit if no history
      }
    });

    return () => {
      backListener.then((listener) => listener.remove());
    };
  }, [navigate]);

  return {
    goBack: () => navigate(-1),
    handleManualBackButton: () => navigate(-1),
  };
};