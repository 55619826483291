import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
} from "@mui/material";
import cancelBooking from "../../assets/images/cancel-booking.svg";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useCancelBookingSeminarMutation } from "store/reducers/seminar";

export interface PropType {
  seminarID: string;
}

const CancelBooking = ({ seminarID }: PropType) => {
  const [cancelSeminar, { data, isError, isSuccess, error, isLoading }] =
    useCancelBookingSeminarMutation();

  useEffect(() => {
    if (isError || isSuccess) {
      enqueueSnackbar(
        (error as string | (string & any[])) || (data?.message as string),
        {
          variant: isSuccess ? "success" : "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 2000,
        }
      );
    }
    if (isSuccess) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.message, error, isError, isSuccess]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onCancelBooking = async () => {
    const saveValues = {
      ID: seminarID,
      IsBooked: false,
    };
    await cancelSeminar(saveValues);
  };

  return (
    <>
      <Button className="btn btn-outline-danger btn-sm" onClick={handleOpen}>
        <span>Cancel Booking</span>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        className="custom-dialog main-wrapper cancle-booking_dialog"
      >
        <DialogTitle className="custom-dialog-title p-0 d-flex justify-content-between">
          <Button className="dialog-close p-0" aria-label="close" onClick={handleClose}>
            <i className="mmm-cancel"></i>
          </Button>
        </DialogTitle>
        <Box className="custom-form">
          <Box className="cancle-booking_content">
            <Box className="cancle-booking_image text-center">
              <img src={cancelBooking} className="img-fluid dark-logo" alt="" />
            </Box>
            <h3 className="text-center mt-0">
              Are you sure you want to cancel your booking?
            </h3>
          </Box>
          <div className="form-block action-btns d-flex align-items-center justify-content-between">
            <Button className="btn btn-link btn-md" onClick={handleClose}>
              Back
            </Button>
            <Button
              className="btn btn-primary btn-md"
              onClick={onCancelBooking}
              disabled={isLoading}
            >
              Cancel Booking
              {isLoading ? (
                <CircularProgress
                  size={16}
                  sx={{ color: "white", marginLeft: 1 }}
                />
              ) : null}
            </Button>
          </div>
        </Box>
      </Dialog>
    </>
  );
};

export default CancelBooking;
