import { Box } from "@mui/material"
import noRecordfound from "../assets/images/no-resultfound.svg";


const NoDataFound = () => {
    return (
        <Box className="no-resultfound mt-4 mb-4">
            <img
                src={noRecordfound}
                className="img-fluid dark-logo"
                alt="No Record Found"
            />
        </Box>
    )
}

export default NoDataFound