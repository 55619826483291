import { Button, Typography } from "@mui/material";
import EventBanner from "components/EventBanner";
import { useNavigate } from "react-router";

const SeminarBanner = () => {
  const navigate = useNavigate();

  return (
    <EventBanner>
      <div className="banner-text flex-fill">
        <Typography variant="h2">Book onto a seminar</Typography>
        <Typography variant="body1">
          Join our team at an in-person seminar below. Simply choose the topic
          that interests you, browse the available dates and times, and confirm
          your spot in just a few steps!
        </Typography>
      </div>
      <div className="banner-action">
        <Button
          className="btn btn-primary btn-lg"
          sx={{ flexWrap: "nowrap !important" }}
          aria-label="book a seminar"
          onClick={() => navigate("/employee/seminars/myBookings")}
        >
          <i className="mmm-bookmark"></i>
          <span style={{ whiteSpace: "nowrap" }}>My Bookings</span>
        </Button>
      </div>
    </EventBanner>
  );
};

export default SeminarBanner;
