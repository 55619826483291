import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import { Calculate, CalculationResponse } from "types/calculator"; // Import the new types

export const CALCULATOR_API_REDUCER_KEY = "calculatorApi";

export const calculatorApi = createApi({
  reducerPath: CALCULATOR_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Calculator"],
  endpoints: (builder) => ({
    getCalculation: builder.query<CalculationResponse, Calculate>({ // Update the response type
      query: (body: Calculate) => {
        return {
          url: ApiConstant.GET_CALCULATION,
          method: "POST",
          body,
        };
      },
      providesTags: ["Calculator"],
    }),
  }),
});

export const { useLazyGetCalculationQuery } = calculatorApi;