import { appENV, appENVMapping } from "constants/General";
import moment from "moment";

type FormikTouched = {
  [field: string]: any;
};

type FormikErrors = {
  [field: string]: any;
};

const planStatusColor: { [key: string]: string } = {
  Accepted: "#0B8E51",
  Cancelled: "#D4351C",
  default: "#FF832B",
};

function checkFieldError(
  touched: FormikTouched,
  errors: FormikErrors,
  fieldName: string,
  index?: number,
  subField?: string
): boolean {
  if (index !== undefined) {
    if (
      !(
        touched[fieldName] &&
        touched[fieldName][index] &&
        subField &&
        touched[fieldName][index][subField] &&
        errors[fieldName] &&
        errors[fieldName][index] &&
        errors[fieldName][index][subField]
      )
    ) {
      return false;
    } else {
      return (
        !!touched[fieldName][index][subField] &&
        !!errors[fieldName][index][subField]
      );
    }
  } else {
    if (!(touched[fieldName] && errors[fieldName])) {
      return false;
    } else {
      return !!touched[fieldName] && !!errors[fieldName];
    }
  }
}

const redirectToURL = (url: string, newTab: boolean) => {
  const tab = newTab ? "_blank" : "_self";
  if (url) {
    window.open(url, tab);
  }
};

const poundConverter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const simpleCurrencyConverter = new Intl.NumberFormat();

const formatCurrency = (amount: number | string, inPounds: boolean = true): string => {
  if (!amount) {
    amount = 0;
  }

  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

  return inPounds
    ? poundConverter.format(numericAmount)
    : simpleCurrencyConverter.format(numericAmount);
};

const formatDate = (date?: string, format: string = 'DD/MM/YYYY') => {
  if (!date) {
    return date
  }

  return moment(date).format(format);
}

const dateOfBirthFormat = (date: string) => {
  const yearsOld = moment().diff(date, "years");
  return {
    formattedDate: moment(date).format("DD MMM YYYY"),
    yearsOld: `(${yearsOld} years old)`,
  };
}

const getRelativeTime = (inputDate: string) => {
  const now = moment();
  const inputMoment = moment(inputDate);

  const diffInMinutes = now.diff(inputMoment, 'minutes');
  const diffInHours = now.diff(inputMoment, 'hours');
  const diffInDays = now.diff(inputMoment, 'days');
  const diffInYears = now.diff(inputMoment, 'years');

  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  } else if (diffInDays < 365) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  } else {
    return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;
  }
};

const capitalizeWord = (word: string) => {
  if (!word) return "";
  return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
}

const getENVMapping = () => {
  const appEnv = process.env.REACT_APP_ENV?.toLowerCase();
  let envValue = "1";
  switch (appEnv) {
    case appENV.DEVELOPMENT:
      envValue = appENVMapping.DEVELOPMENT
      break;
    case appENV.STAGING:
      envValue = appENVMapping.STAGING
      break;
    case appENV.PRODUCTION:
      envValue = appENVMapping.PRODUCTION
      break;
    default:
      envValue = appENVMapping.LOCAL
  }
  return envValue
}

const getPayPeriodValue = (period: string) => {
   
  const PAY_PERIODS_VALUE: { [key: string]: number } = {
    "4 weekly": 13,
    "4_weekly": 13,
    "4-weekly": 13,
    "fortnightly": 26,
    "weekly": 52.143,
    "monthly": 12,
  }
  
  return PAY_PERIODS_VALUE[period] || 12
}

export { checkFieldError, formatCurrency, formatDate, planStatusColor, redirectToURL, dateOfBirthFormat, capitalizeWord, getRelativeTime, getENVMapping, getPayPeriodValue };
