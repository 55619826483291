import React, { useEffect } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persister } from "./store/index";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserHistory } from "history";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Notistack from "components/third-party/Notistack";
import { Auth0Provider as AuthProvider } from "./contexts/Auth0Context";
// Custom css files
import "./assets/fonts/icons.css";
import "./assets/scss/main.scss";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import Error500 from "pages/maintenance/500";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { PlansProvider } from "contexts/PlansContext";
import { BrowserRouter } from "react-router-dom";
import { setupIonicReact, isPlatform } from "@ionic/react";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { EnvProvider } from "contexts/EnvProivder";
import { useEnv } from "hooks/useEnv";
import LottieLoader from "components/LottieLoader";
import { isCapacitorApp } from "utils/utils";

const RootComponent = () => {
  const { envData, isENVLoading } = useEnv();

  useEffect(() => {
    Sentry.init({
      dsn: envData?.REACT_APP_SENTRY_KEY,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ], // Performance Monitoring
      tracesSampleRate: 1.0,
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: envData?.REACT_APP_ENV,
      ignoreErrors: ["^(Token is (expired|invalid))$", "ApolloError"],
      release: envData?.REACT_APP_SENTRY_RELEASE_VERSION,
    });
    if (isCapacitorApp()) {
      document.body.classList.add("native-only");
      const isIOS = isPlatform("ios");
      if (isIOS) {
        document.body.classList.add("notch-device");
      }
    }
  }, []);

  if (isENVLoading) return <LottieLoader />;

  const iosOrAndroid = isPlatform("hybrid");
  const appId = process.env.REACT_APP_APPID || "";
  const callbackUri = iosOrAndroid
    ? `${appId}://${envData?.REACT_APP_AUTH_DOMAIN}/capacitor/${appId}/callback`
    : window.location.origin;

  console.log("Final Callback URI:", callbackUri);

  return (
    <Auth0Provider
      domain={envData?.REACT_APP_AUTH_DOMAIN ?? ""}
      clientId={envData?.REACT_APP_AUTTH_CLIENT_ID ?? ""}
      useRefreshTokens
      useRefreshTokensFallback={true}
      cacheLocation="localstorage"
      // onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: callbackUri,
        audience: envData?.REACT_APP_AUTH_AUDIENCE,
        scope:
          "profile read:current_user update:users_app_metadata update:users update:current_user_metadata openid email read:roles",
      }}
    >
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persister}>
          {/* <React.StrictMode> */}
          <CssBaseline />
          <Notistack>
            <AuthProvider>
              <Sentry.ErrorBoundary fallback={() => <Error500 />}>
                <ThemeProvider theme={theme}>
                  <PlansProvider>
                    <div className="main-wrapper">
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </div>
                  </PlansProvider>
                </ThemeProvider>
              </Sentry.ErrorBoundary>
            </AuthProvider>
          </Notistack>
          {/* </React.StrictMode> */}
        </PersistGate>
      </ReduxProvider>
    </Auth0Provider>
  );
};

setupIonicReact();
defineCustomElements(window);

const history = createBrowserHistory();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const theme = createTheme({
  typography: {
    fontFamily: '"elza", sans-serif !important', // Set the desired font-family
  },
  palette: {
    mmm: {
      main: "#7236ae",
    },
  },
});

const UnSecuredPageWarning = () => (
  <div>
    <h1>
      If you see this page, Webb App link you have clicked on is under
      Clickjacking security attack.
    </h1>
    <h2>
      Please inform team with the reference of the application from where you
      clicked this link
    </h2>
  </div>
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker-v1.js") // Assuming your service worker file is named service-worker-v1.js
      .then((registration) => {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      })
      .catch((error) => {
        console.log("ServiceWorker registration failed: ", error);
      });
  });
}

const isIframeAllowed = window.location.pathname === '/calculator';
// Won't render the application if WebApp is under Clickjacking attack
if (window.self === window.top || isIframeAllowed) {
  root.render(
    <EnvProvider>
      <RootComponent />
    </EnvProvider>
  );
} else {
  root.render(<UnSecuredPageWarning />);
}
/* serviceWorkerRegistration.register(); */
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
