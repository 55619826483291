import { useEffect, useState } from "react";
import { PLASMIC } from "constants/Plasmic";
import Routes from "../src/routes/index";
import ModalProvider from "mui-modal-provider";
import useAuth from "hooks/useAuth";
import Loader from "components/Loader";
import { PlasmicRootProvider } from "@plasmicapp/loader-react";
import { useAuth0 } from "@auth0/auth0-react";
import { App as CapApp } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { GlobalDebug } from "utils/remove-consoles";
import useInactivityLogout from "hooks/useInactivityLogout";
import Chatbtn from "components/third-party/ChatBtn";
import { useBackButtonService } from "components/BackButtonService";
import ScrollToTop from "hooks/ScrollToTop";
import { SplashScreen } from "@capacitor/splash-screen";
import { isCapacitorApp } from "utils/utils";
import { useFirebase } from "./hooks/firebaseConfig";
import { PushNotificationProvider } from "contexts/PushNotificationContext";
import { useNavigate } from "react-router";
import { useEnv } from "hooks/useEnv";
import { PushNotifications } from "@capacitor/push-notifications";
const App = () => {
  const { app, analytics, FirebaseCrashlytics, FirebaseAnalytics } =
    useFirebase();
  const { isLoading } = useAuth();
  const { loading: logoutLoading } = useInactivityLogout();
  const { handleRedirectCallback } = useAuth0();

  const navigate = useNavigate();
  const { envData, isENVLoading } = useEnv();
  useBackButtonService();
  useEffect(() => {   
    if (isCapacitorApp()) {
      PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Push received in foreground:', notification);
      });
    } 
  
    return () => {
      if (isCapacitorApp()) {
        PushNotifications.removeAllListeners();
      }
    };
  }, []);
 
  useEffect(() => {
    setTimeout(() => {
      SplashScreen.hide();
    }, 3000);

    if (
      process.env.REACT_APP_ENV === "PRODUCTION" ||
      process.env.REACT_APP_ENV === "STAGING"
    ) {
      GlobalDebug(false);
      console.log = console.warn = console.error = console.debug = () => {};
    }
    CapApp.addListener("appUrlOpen", async ({ url }) => {
      console.log("App Open", url);
      const pageURL = new URL(url).pathname
      const appAuthURL = envData?.REACT_APP_AUTH_DOMAIN || '';
      if ((!url.includes(appAuthURL) || !url.includes(process.env.REACT_APP_APPID ||'') ) && pageURL) {
        navigate(pageURL);
      }
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        await handleRedirectCallback(url);
      }
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  if (isLoading || logoutLoading) return <Loader />;

  const renderApp = () => {
    if (isCapacitorApp()) {
      return (
        <PushNotificationProvider>
          <Routes />
        </PushNotificationProvider>
      );
    } else {
      return <Routes />;
    }
  };

  return (
    <PlasmicRootProvider loader={PLASMIC} disableLoadingBoundary={false}>
      <ModalProvider>
        <ScrollToTop />
          {renderApp()}
        <Chatbtn />
      </ModalProvider>
    </PlasmicRootProvider>
  );
};

declare module "@mui/material/styles" {
  interface Palette {
    mmm?: {
      main: string;
    };
  }

  interface PaletteOptions {
    mmm?: {
      main: string;
    };
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    mmm: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    mmm: true;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsColorOverrides {
    mmm: true;
  }
}

export default App;
