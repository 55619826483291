import { ORGANISATION_TYPE, PRODUCTS } from "constants/General";
import { UserProfile } from "types/auth";
import { setCookies } from "./cookies";
import { Capacitor } from "@capacitor/core";
import { AppENVConfig } from "contexts/EnvProivder";

const checkProductListing = (productName: string, employerDetails: UserProfile | null | undefined) => {
  const name = productName?.toLowerCase();

  if (employerDetails?.OrganisationType === "NHS" && (name === PRODUCTS.INVESTMENT_ADVICE || name === PRODUCTS.AVCWISE)) {
    return false;
  } else if (employerDetails?.OrganisationType === "NHS" && name === PRODUCTS.NHS && !employerDetails?.EnableNhs) {
    return false
  } else if (ORGANISATION_TYPE.LGPS.toLowerCase() === employerDetails?.OrganisationType?.toLowerCase() && name === PRODUCTS.PENSION_TOP_UP) {
    return false;
  } else if (ORGANISATION_TYPE.PRIVATE.toLowerCase() === employerDetails?.OrganisationType?.toLowerCase() && name === PRODUCTS.AVCWISE) {
    return false;
  } else if (ORGANISATION_TYPE.PRIVATE.toLowerCase() === employerDetails?.OrganisationType?.toLowerCase() && name === PRODUCTS.PENSION_TOP_UP && !employerDetails.EnablePensionTopUp) {
    return false;
  } else if (ORGANISATION_TYPE.LGPS.toLowerCase() === employerDetails?.OrganisationType?.toLowerCase() && name === PRODUCTS.AVCWISE && !employerDetails?.EnableAvcwise) {
    return false;
  } else if (name === PRODUCTS.SNOOP && !employerDetails?.EnableSnoop) {
    return false;
  } else if (name === PRODUCTS.INVESTMENT_ADVICE && !employerDetails?.EnableInvestmentAdvice) {
    return false;
  } else if (name === PRODUCTS.MORTGAGE_SERVICE && !employerDetails?.EnableMortgageService) {
    return false
  } else if (name === PRODUCTS.WILLSERVICE && !employerDetails?.EnableWillService) {
    return false
  } else if (name === PRODUCTS.AVCWISE && !employerDetails?.EnableAvcwise) {
    return false
  } else if (name === PRODUCTS.WEALTHIFY && !employerDetails?.EnableWealthify) {
    return false
  } else if (name === PRODUCTS.NUDGE && !employerDetails?.EnableNudge) {
    return false
  }
  else {
    return true;
  }

}

const checkEmployerSubDomain = (employerSubDomain: string, envData: AppENVConfig, orgRoleId?: string, employerId?: string) => {
  const platform = Capacitor.getPlatform();
  if (platform === "ios" || platform === "android") {
    return;
  }
  const originSubdomain = window.location.hostname?.split(".")[0];
  const expectedSubDomain = employerSubDomain?.toString()?.toLowerCase();
  const genericSubDomain =  envData?.REACT_APP_GENERIC_URL?.split(".")[0];
  const appGenericURL = `https://${envData?.REACT_APP_GENERIC_URL}`;
  const expectedOriginURL = `https://${expectedSubDomain}.${envData?.REACT_APP_GENERIC_URL}`;
  const isLocalEnvironment = process.env.REACT_APP_ENV === "local";

  if (originSubdomain === expectedSubDomain) {
    return;
  }

  if (!isLocalEnvironment && expectedSubDomain && originSubdomain !== expectedSubDomain) {
    setCookies('orgRoleId', orgRoleId)
    setCookies('employerId', employerId)
    window.location.href = expectedOriginURL;
  } else if (!isLocalEnvironment && !expectedSubDomain && originSubdomain !== genericSubDomain) {
    setCookies('orgRoleId', orgRoleId)
    setCookies('employerId', employerId)
    window.location.href = appGenericURL;
  }
};

export { checkProductListing, checkEmployerSubDomain };