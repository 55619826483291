import { useLocation, Navigate } from "react-router-dom";
import checkIfContainsInternalLink from "helper/containsInternalLink";
import { getBaseURL } from "utils/utils";
import {
  ADMIN_APP_DEFAULT_PATH,
  EMPLOYEE_APP_DEFAULT_PATH,
} from "../../config";
import useAuth from "../../hooks/useAuth";

import { GuardProps } from "../../types/auth";
import { useEnv } from "hooks/useEnv";
import { AppENVConfig } from "contexts/EnvProivder";

const GuestGuard = ({ children }: GuardProps) => {
  const { envData } = useEnv() as { envData: AppENVConfig };
  const { isLoggedIn, user } = useAuth();
  const location = useLocation();
  const redirectedURl: string = location?.state?.from?.pathname ?? ""
  console.log(redirectedURl, "redirectedURl")
  if (checkIfContainsInternalLink(redirectedURl)) {
    console.log("Loctaion Path Name", location?.state?.from.pathname)
    localStorage.setItem("baseURl", location?.state?.from.pathname)
  }
  if (isLoggedIn) {
    if (user?.RoleName === "Employee") {
      const baseURl = getBaseURL(envData)
      console.log("GuestGuard baseUrl" ,baseURl)
    }
    const default_path = user?.RoleName === "Employee" ? EMPLOYEE_APP_DEFAULT_PATH : ADMIN_APP_DEFAULT_PATH;
    const url = location?.state?.from ? location?.state?.from : default_path;
    return <Navigate to={url} state={{ from: location }} replace />;
  }

  return children;
};

export default GuestGuard;
