import { useState, useEffect } from "react";
import { initializeApp, getApps, getApp } from "firebase/app";
import { FirebaseCrashlytics } from "@capacitor-firebase/crashlytics";
import { getAnalytics, isSupported } from "firebase/analytics";
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { useEnv } from "./useEnv";


export const useFirebase = () => {
  const { envData, isENVLoading } = useEnv();
  const [app, setApp] = useState<any>(null);
  const [analytics, setAnalytics] = useState<any>(null);

  useEffect(() => {
    if (isENVLoading || !envData) return; 

    // ✅ Firebase Configuration
    const firebaseConfig = {
      apiKey: envData.REACT_APP_API_KEY.toString(),
      authDomain: envData.REACT_APP_GOOGLE_AUTH_DOMAIN.toString(),
      projectId: envData.REACT_APP_PROJECT_ID.toString(),
      storageBucket: envData.REACT_APP_STORAGE_BUCKET.toString(),
      messagingSenderId: envData.REACT_APP_MESSAGING_SENDER_ID.toString(),
      appId: envData.REACT_APP_GOOGLE_APP_ID.toString(),
    };

    Object.entries(firebaseConfig).forEach(([key, value]) => {
      if (!value) {
        console.warn(`⚠️ Firebase Config Warning: ${key} is missing in .env file!`);
      }
    });

    const firebaseApp = getApps().length ? getApp() : initializeApp(firebaseConfig);
    setApp(firebaseApp);

    // ✅ Initialize Firebase Analytics (Only if Supported & Not Web)
    isSupported()
      .then((supported) => {
        if (supported && Capacitor.getPlatform() !== "web") {
          const analyticsInstance = getAnalytics(firebaseApp);
          setAnalytics(analyticsInstance);
          console.log("📊 Firebase Analytics initialized!");
        } else {
          console.warn("⚠️ Firebase Analytics is not supported in this environment.");
        }
      })
      .catch((err) => console.error("❌ Firebase Analytics Error:", err));

    // ✅ Initialize Crashlytics
    const initializeCrashlytics = async () => {
      if (Capacitor.getPlatform() !== "web") {
        try {
          await FirebaseCrashlytics.setEnabled({ enabled: true });
          console.log("🔥 Crashlytics enabled");
        } catch (error) {
          console.error("❌ Failed to enable Crashlytics:", error);
        }
      } else {
        console.log("Crashlytics is not supported on the web.");
      }
    };

    initializeCrashlytics();

    // ✅ Log Firebase Initialization for Native Platforms
    if (Capacitor.isNativePlatform()) {
      FirebaseAnalytics.logEvent({
        name: "firebase_initialized",
        params: { platform: Capacitor.getPlatform() },
      })
        .then(() => console.log("🔥 Firebase Analytics Initialized (Native)"))
        .catch((err) => console.error("🔥 Firebase Analytics Error:", err));
    }
  }, [envData, isENVLoading]); // Runs only when `envData` is available

  return { app, analytics,FirebaseCrashlytics, FirebaseAnalytics };
};
