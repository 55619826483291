import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import { DeviceResponse, Device } from "types/device";

export const DEVICE_API_REDUCER_KEY = "deviceApi";

export const deviceApi = createApi({
  reducerPath: DEVICE_API_REDUCER_KEY,
  baseQuery,
  endpoints: (builder) => ({
    manageEmployeeDevice: builder.mutation<DeviceResponse, Device>({
      query: (args) => {
        return {
          url: ApiConstant.MANAGE_EMPLOYEE_DEVICE,
          method: "POST",
          body: args,
        };
      },
    }),
    deviceRegistration: builder.mutation<DeviceResponse, Device>({
      query: (args) => {
        return {
          url: ApiConstant.DEVICE_REGISTRATION,
          method: "POST",
          body: args,
          headers: {
            "Content-Type": "application/json",
            "apikey": "a5274744-f550-409a-95a1-2d3b8f9fe803"
          }
        };
      },
    }),
  }),
});

export const {
  useManageEmployeeDeviceMutation,
  useDeviceRegistrationMutation,
} = deviceApi ;
