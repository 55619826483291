import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { ApiConstant } from "../../constants/ApiConstants";
import {
  AmendActivePlanRequest,
  CancelActivePlanRequest,
  CreateTempPlanRequest,
  CreateTempPlanResponse,
  GetAllPlanTypeRespnse,
  GetPayPeriodByEmployerResponse,
  GetPlanDetailsResponse,
  GetPlanReasonsRequest,
  GetPlanReasonsResponse,
  NationalMinimumWageCalculations,
  NationalMinimumWageFormValues,
  PaginatedPlans,
  PlanComments,
  PlanDetails,
  PlanStatus,
  SchemeDetails,
  TempPlan,
  UpdatePlanRequest,
  UpdateTempPlanRequest,
  UpdateTempPlanResponse,
  ValidateEmployeeNumberRequest,
  ValidateEmployeeNumberResponse,
  ValidateNINumberRequest,
  ValidateNINumberResponse,
  ValidateSearchAddressByIdRequest,
  ValidateSearchAddressByIdResponse,
  ValidateSearchAddressRequest,
  ValidateSearchAddressResponse,
} from "types/privatePension";
import { isEmpty } from "lodash";
import { DataResponse } from "types";
import { SearchParam } from "types/employee";

export const PRIVATE_PENSION_API_REDUCER_KEY = "privatePension";

export const privatePensionApi = createApi({
  reducerPath: PRIVATE_PENSION_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["PrivatePension"],
  endpoints: (builder) => ({
    validateNINumber: builder.query<
      ValidateNINumberResponse,
      ValidateNINumberRequest
    >({
      query: (body) => ({
        url: ApiConstant.VALIDATE_NI_NUMBER,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      providesTags: ["PrivatePension"],
    }),
    searchAddress: builder.query<
    ValidateSearchAddressResponse,
    ValidateSearchAddressRequest
    >({
      query: (body) => {
        return {
          url: ApiConstant.SEARCH_ADDRESS,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      providesTags: ["PrivatePension"],
    }),
    searchAddressById: builder.query<
    ValidateSearchAddressByIdResponse,
    ValidateSearchAddressByIdRequest
    >({
      query: (body) => {
        return {
          url: ApiConstant.SEARCH_ADDRESS_BY_ID,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      providesTags: ["PrivatePension"],
    }),
    validateEmployeeNumber: builder.query<
      ValidateEmployeeNumberResponse,
      ValidateEmployeeNumberRequest
    >({
      query: (body) => ({
        url: ApiConstant.VALIDATE_EMPLOYEE_NUMBER,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      providesTags: ["PrivatePension"],
    }),
    getTempPlan: builder.query<
      { message: string; statusCode: number; data: TempPlan },
      string
    >({
      query: (id) => {
        return {
          url: `${ApiConstant.GET_TEMP_PLAN}/${id}`,
          method: "GET",
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      providesTags: ["PrivatePension"],
    }),
    createTempPlan: builder.mutation<
      CreateTempPlanResponse,
      CreateTempPlanRequest
    >({
      query: (body) => ({
        url: ApiConstant.CREATE_TEMP_PLAN,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["PrivatePension"],
    }),
    updateTempPlan: builder.mutation<
      UpdateTempPlanResponse,
      UpdateTempPlanRequest
    >({
      query: ({ ID, ...body }) => ({
        url: `${ApiConstant.UPDATE_TEMP_PLAN}/${ID}`,
        method: "PATCH",
        body,
      }),
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
      invalidatesTags: ["PrivatePension"],
    }),
    submitTempPlan: builder.mutation<{ success: string }, { ID: string }>({
      query: ({ ID }) => ({
        url: `${ApiConstant.SUBMIT_TEMP_PLAN}/${ID}`,
        method: "POST",
      }),
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
    }),
    getPayPeriodByEmployer: builder.query<GetPayPeriodByEmployerResponse,{ ID: string, Period?: string }>({
      query: ({ ID, Period }) => ({
        url: `${ApiConstant.GET_PAY_PERIOD_BY_EMPLOYER}/${ID}`,
        method: "POST",
        body: Period ? {Period} : {}
      }),
      providesTags: ["PrivatePension"],
    }),
    getPlanReasons: builder.query<
      GetPlanReasonsResponse,
      GetPlanReasonsRequest
    >({
      query: (body) => ({
        url: ApiConstant.GET_PLAN_REASONS,
        method: "POST",
        body,
      }),
      providesTags: ["PrivatePension"],
    }),
    getPlanDetails: builder.query<
      GetPlanDetailsResponse,
      { EmployerID: string }
    >({
      query: (body) => ({
        url: ApiConstant.GET_PLAN_DETAILS,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
      providesTags: ["PrivatePension"],
    }),
    amendActivePlan: builder.mutation<
      DataResponse<any>,
      AmendActivePlanRequest
    >({
      query: ({
        ID,
        AmendmentReasonID,
        AmendmentReasonOther,
        AvcAmount,
        AvcAmountPercentage,
      }) => ({
        url: `${ApiConstant.AMEND_ACTIVE_PLAN}/${ID}`,
        method: "POST",
        body: {
          AmendmentReasonID,
          AmendmentReasonOther,
          AvcAmount,
          AvcAmountPercentage,
        },
      }),
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
      invalidatesTags: ["PrivatePension"],
    }),
    plansByOrgAdmin: builder.query<PaginatedPlans, {}>({
      query: (args: SearchParam) => {
        const {
          pageNo = 1,
          size = 30,
          EmployerID = "",
          filter = []
        } = args;

        const body = {
          pageNo,
          size,
          EmployerID,
          filter,
        };

        return {
          url: ApiConstant.GET_PLANS_BY_ORG_ADMIN,
          method: "POST",
          body: { ...body },
        };
      },
      providesTags: ["PrivatePension"],
    }),
    planById: builder.query<{ data: PlanDetails }, {}>({
      query: ({ planId }: { planId: string }) => {
        return {
          url: `${ApiConstant.GET_PLANS_BY_ID}/${planId}`,
          method: "GET",
        };
      },
      providesTags: ["PrivatePension"],
    }),
    getCommentsByPlanId: builder.query<{ data: PlanComments[] }, {}>({
      query: ({ planId }: { planId: string }) => {
        return {
          url: `${ApiConstant.PLAN_COMMENTS}/${planId}`,
          method: "GET",
        };
      },
      providesTags: ["PrivatePension"],
    }),
    getAllPlanStatus: builder.query<{ data: PlanStatus[] }, {}>({
      query: () => {
        return {
          url: `${ApiConstant.GET_PLAN_STATUS}`,
          method: "GET",
        };
      },
      providesTags: ["PrivatePension"],
    }),
    getNMWCalculations: builder.query<{ data: NationalMinimumWageCalculations, message: string }, NationalMinimumWageFormValues>({
      query: (body) => {
        return {
          url: `${ApiConstant.GET_PLAN_NMW_CALCUATION}`,
          method: "POST",
          body
        };
      },
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
    }),
    updateCommentById: builder.mutation<
      DataResponse<any>,
      { ID: string; Body: string }
    >({
      query: ({ ID, Body }) => ({
        url: `${ApiConstant.PLAN_COMMENTS}/${ID}`,
        method: "PATCH",
        body: { Body },
      }),
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
      invalidatesTags: ["PrivatePension"],
    }),
    addCommentByPlanId: builder.mutation<
      DataResponse<any>,
      { PlanID: string; Body: string }
    >({
      query: ({ PlanID, Body }) => ({
        url: ApiConstant.PLAN_COMMENTS,
        method: "POST",
        body: { PlanID, Body },
      }),
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
      invalidatesTags: ["PrivatePension"],
    }),
    deleteCommentById: builder.mutation<DataResponse<any>, { ID: string }>({
      query: ({ ID }) => ({
        url: `${ApiConstant.PLAN_COMMENTS}/${ID}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
      invalidatesTags: ["PrivatePension"],
    }),
    cancelActivePlan: builder.mutation<
      DataResponse<any>,
      CancelActivePlanRequest
    >({
      query: ({ ID, CancellationReasonID, CancellationReasonOther }) => ({
        url: `${ApiConstant.CANCEL_ACTIVE_PLAN}/${ID}`,
        method: "POST",
        body: { CancellationReasonID, CancellationReasonOther },
      }),
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
      invalidatesTags: ["PrivatePension"],
    }),
    updatePlanByAdmin: builder.mutation<
      DataResponse<any>,
      UpdatePlanRequest
    >({
      query: ({
        PlanID,
        ...restValues
      }) => ({
        url: `${ApiConstant.UPDATE_PLAN_BY_ADMIN}/${PlanID}`,
        method: "PATCH",
        body: restValues,
      }),
      transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
      invalidatesTags: ["PrivatePension"],
    }),
    getAllPlanType: builder.query<GetAllPlanTypeRespnse, {}>({query:()=>{
      return{
        url: ApiConstant.GET_ALL_PLAN_TYPE,
        method: "GET"
      }
    }}),
    getUsersForFilter: builder.query<DataResponse<any>,{}>({query:(body)=>{
      return {
        url: ApiConstant.GET_USERS_FOR_FILTER,
        method: "POST",
        body
      }
    }}),
    downloadCSVORG: builder.query<DataResponse<any>,{}>({query:(body)=>{
      return {
        url: ApiConstant.DOWNLOAD_PLANS_ORG,
        method: "POST",
        body
      }
    },
     transformErrorResponse: (response: {
        data: { error: any; message: string };
        status: number;
      }) => {
        const error = response?.data?.error;
        if (Array.isArray(error) && !isEmpty(error)) {
          return error[0];
        } else {
          return response?.data?.message;
        }
      },
    providesTags: ["PrivatePension"]}),
    getSchemeByID: builder.query<DataResponse<SchemeDetails>,{ ID: string }>({query:({ ID })=>{
      return {
        url: `${ApiConstant.GET_SCHEME_BY_ID}/${ID}`,
        method: "GET"
      }
    }, providesTags: ["PrivatePension"]})
  }),
});

export const {
  useCreateTempPlanMutation,
  useGetPayPeriodByEmployerQuery,
  useLazyGetPayPeriodByEmployerQuery,
  useGetPlanReasonsQuery,
  useLazyGetPlanReasonsQuery,
  useLazyGetTempPlanQuery,
  useGetPlanDetailsQuery,
  useGetCommentsByPlanIdQuery,
  useLazyGetCommentsByPlanIdQuery,
  useGetAllPlanStatusQuery,
  useLazyGetNMWCalculationsQuery,
  useUpdateCommentByIdMutation,
  useAddCommentByPlanIdMutation,
  useDeleteCommentByIdMutation,
  useLazyGetPlanDetailsQuery,
  useGetTempPlanQuery,
  useGetSchemeByIDQuery,
  usePlanByIdQuery,
  useLazyPlanByIdQuery,
  usePlansByOrgAdminQuery,
  useSubmitTempPlanMutation,
  useAmendActivePlanMutation,
  useCancelActivePlanMutation,
  useUpdateTempPlanMutation,
  useLazyValidateNINumberQuery,
  useLazyValidateEmployeeNumberQuery,
  useUpdatePlanByAdminMutation,
  useGetAllPlanTypeQuery,
  useLazyGetUsersForFilterQuery,
  useLazyDownloadCSVORGQuery,
  useSearchAddressQuery,
  useLazySearchAddressQuery,
  useSearchAddressByIdQuery,
  useLazySearchAddressByIdQuery,
} = privatePensionApi;