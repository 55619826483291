import { Suspense, lazy, useState, useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { createGesture } from "@ionic/react";
import useAuth from "hooks/useAuth";
import { IonContent, IonRefresher, IonRefresherContent } from "@ionic/react";
import { isCapacitorApp } from "utils/utils";
import { SafeArea } from "capacitor-plugin-safe-area";

const Header = lazy(() => import("./Header"));
const Footer = lazy(() => import("./Footer"));

const LoaderWrapper = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
});

const LayoutWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  width: "100%",
});

const ContentWrapper = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  overflow: "hidden",
});

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

const MainLayout = () => {
  const { isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const contentRef = useRef<HTMLIonContentElement>(null); 
  const [historyStack, setHistoryStack] = useState<string[]>([]);
  const [safeInsets, setSafeInsets] = useState({ top: 0, bottom: 0 });

  useEffect(() => {
    setHistoryStack((prevStack) => [...prevStack, location.pathname]);
  }, [location.pathname]);

  const handleRefresh = (event: CustomEvent) => {
    console.log("Refreshing...");
    navigate("/");
    setTimeout(() => {
      navigate(location.pathname, { replace: true });
    }, 10);
  };

  useEffect(() => {
    if (isCapacitorApp()) {
      const setInsets = async () => {
        const { insets } = await SafeArea.getSafeAreaInsets();
        setSafeInsets(insets);
      };
      setInsets();
    }
  }, []);

  useEffect(() => {
    if (!contentRef.current) return;

    const gesture = createGesture({
      el: contentRef.current,
      gestureName: "swipe-back",
      threshold: 10,
      direction: "x",
      onMove: (ev) => {
        if (ev.deltaX > 100 && historyStack.length > 1) {
          navigate(-1);
        }
      },
    });

    gesture.enable(); 

    return () => gesture.destroy();
  }, [historyStack, navigate]);

  const renderLayout = () => {
    if (isCapacitorApp()) {
      return (
        <>
          <Header />
          <LayoutWrapper>
            <ContentWrapper>
              <IonContent fullscreen className="ion-no-padding" ref={contentRef}>
                {isCapacitorApp() && (
                  <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                    <IonRefresherContent
                      pullingText="Pull to refresh"
                      refreshingSpinner="circles"
                    />
                  </IonRefresher>
                )}
                <Outlet />
              </IonContent>
            </ContentWrapper>
          </LayoutWrapper>
        </>
      );
    } else {
      return (
        <>
          <Header />
          <Outlet />
          <Footer />
        </>
      );
    }
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Suspense fallback={<Loader />}>
        <div
          style={{
            paddingTop: isCapacitorApp() ? safeInsets.top : 0,
            paddingBottom: isCapacitorApp() ? safeInsets.bottom : 0,
          }}
        >
          {renderLayout()}
        </div>
      </Suspense>
    );
  }
};

export default MainLayout;
