import useAuth from "../../hooks/useAuth";
import Loader from "components/Loader";
import { getBaseURL, isCapacitorApp } from "utils/utils";
import { GuardProps } from "../../types/auth";
import { Navigate, useLocation } from "react-router";
import { ADMIN_APP_DEFAULT_PATH, EMPLOYEE_APP_DEFAULT_PATH } from "config";
import { useEnv } from "hooks/useEnv";
import { AppENVConfig } from "contexts/EnvProivder";

const BaseRoleGuard = ({ children }: GuardProps) => {
  const { envData } = useEnv() as { envData: AppENVConfig };
  const { user, isLoggingOut, isLoggingIn, isLoggedIn } = useAuth();
  const location = useLocation();
  if (isLoggingOut || isLoggingIn) {
    return <Loader />;
  }

  if (user?.RoleName === "Employee" && isLoggedIn) {
    const baseURl = getBaseURL(envData)
    console.log("BaseGuard baseUrl" ,baseURl)
    return <Navigate state={{ from: location }} to={baseURl} replace />;
  } else {
    if (user?.singleLogin && isCapacitorApp() && isLoggedIn) {
      return <Navigate state={{ from: location }} to={"/unauthorized-native-access"} replace />
    }
    return <Navigate state={{ from: location }} to={ADMIN_APP_DEFAULT_PATH} replace />;
  }
};

export default BaseRoleGuard;
