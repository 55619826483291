import { lazy } from "react"
import Loadable from "../components/Loadable"

const Calculator = Loadable(lazy(() => import("../calculator")))
const CalculatorRoutes = {
    path: "/",
    children: [
      {
        path: "/",
        children: [
          {
            path: "calculator",
            element: <Calculator />,
          },
        ],
      },
    ],
}

export default CalculatorRoutes
