import React, { createContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import axiosServices from "utils/axios";
import { ApiConstant } from "constants/ApiConstants";
import { getENVMapping } from "helpers";

// Define types
interface DecodedTokenPayload {
  iat: string;
  exp: string;
  secrets: string;
}

export interface AppENVConfig {
  REACT_APP_API_ENDPOINT: string;
  REACT_APP_ADDRESS_KEY: string;
  REACT_APP_USER_MASTER_ID: string;
  REACT_APP_ENV: string;
  REACT_APP_INACTIVITY_TIMEOUT: number;
  REACT_APP_AUTH_DOMAIN: string;
  REACT_APP_AUTTH_CLIENT_ID: string;
  REACT_APP_AUTH_AUDIENCE: string;
  REACT_APP_AUTH_CALLBACK: string;
  REACT_APP_AUTH_DEFAULT_CALLBACK: string;
  REACT_APP_SILENT_LOGIN_CLIENT_ID: string;
  REACT_APP_AVC_DOMAIN: string;
  REACT_APP_AVC_DEFAULT_DOMAIN: string;
  REACT_APP_GENERIC_URL: string;
  REACT_APP_ZENDESK_KEY: string;
  REACT_APP_SUPPORT_EMAIL: string;
  REACT_APP_TRACE_SAMPLE_RATE: number;
  REACT_APP_WEBINAR_URL: string;
  REACT_APP_VERSION: string;
  REACT_APP_NUDGE_REDIRECT_URL: string;
  REACT_APP_SENTRY_RELEASE_VERSION: string;
  REACT_APP_SENTRY_KEY: string;
  [key: string]: string | number;
}

interface EnvContextType {
  envData: AppENVConfig | null;
  isENVLoading: boolean;
}

// Create Context
export const EnvContext = createContext<EnvContextType>({ envData: null, isENVLoading: true });

export const EnvProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [envData, setEnvData] = useState<AppENVConfig | null>(null);
  const [isENVLoading, setIsENVLoading] = useState(true);

  useEffect(() => {
    const fetchEnvData = async () => {
      try {
        const appENV = getENVMapping();
        const { data } = await axiosServices.get(`${ApiConstant.GET_ENV}?ENV=${appENV}`, {
          headers: { "Content-Type": "application/json" },
        });

        let decodedToken: DecodedTokenPayload;
        try {
          decodedToken = jwtDecode(data.data);
        } catch (error) {
          console.error("Error decoding JWT:", error);
          return;
        }

        if (!decodedToken.secrets) {
          throw new Error("Missing secrets key in decoded token");
        }

        const decodedBase64String: string = atob(decodedToken.secrets);
        const decodedBase64: AppENVConfig = JSON.parse(decodedBase64String);

        console.log(decodedBase64, 'decodedBase64');
        
        setEnvData(decodedBase64);
      } catch (error: any) {
        console.error("Error loading env data:", error.response?.data || error.message);
      } finally {
        setIsENVLoading(false);
      }
    };

    fetchEnvData();
  }, []);

  return <EnvContext.Provider value={{ envData, isENVLoading }}>{children}</EnvContext.Provider>;
};
